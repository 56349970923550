/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import path from 'path'
import Layout from 'components/Layout'
import { Header, Share, ShareModal } from 'components/MemorableDays'
import Hero from 'components/Hero'
import {
  TopPaddedPage,
  ReducedTopPaddedPage,
  Spacing50,
} from 'styles/sharedStyle'
import renderContent from 'utils/componentRenderer'
import { decorateListicleContent, groupContentTypes } from 'utils/contentUtils'

const MemorableDaysPageTemplateT14 = (props) => {
  const {
    data: {
      page: {
        content,
        shareBlock,
        shareOverlay,
        locale,
        pageTemplate,
        village: { villageSlug, home: [{ header = {} } = {}] = [] } = {},
        jsonLdSchema,
        village,
      },
    },
    location,
    pageContext: { pageTypeId, pageLevel },
  } = props || {}
  const [preClose, setPreClose] = useState(false)

  const homeUrl = path.join(villageSlug, locale)
  const closeUrl =
    (location && location.state && location.state.prevPathname) || homeUrl

  const hero =
    content &&
    content.find(({ __typename }) => __typename === 'ContentfulCompHeroHer01')

  const {
    isCondensed,
    headline: heroContent,
    media: [media] = [],
    ...heroRest
  } = hero || {}

  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        isCondensed,
        content: heroContent,
        fullScreen: true,
        image: heroImage,
        pageTemplate,
        video: heroVideo,
        share: shareBlock && {
          id: 'memDaysShare',
          label: shareBlock.heroCta || shareBlock.ctaLabel,
        },
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
        ...heroRest,
      }
    : null

  const processedContent = content
    ? decorateListicleContent(groupContentTypes(content))
    : []

  const ContentWrapper =
    heroProps || pageTypeId === 'map' ? ReducedTopPaddedPage : TopPaddedPage

  const logo =
    header &&
    header.logoIcon &&
    header.logoIcon.code &&
    header.logoIcon.code.code

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>
      <Header
        {...(shareOverlay
          ? {
              closeClick: () => setPreClose(true),
            }
          : {
              closeUrl,
            })}
        homeUrl={homeUrl}
        logo={logo}
        position="absolute"
      />
      {heroProps && <Hero {...heroProps} />}
      <Spacing50 />
      <ContentWrapper>{renderContent(processedContent, props)}</ContentWrapper>
      {!!shareBlock && (
        <Share id="memDaysShare" {...shareBlock} showPinterest={false} />
      )}
      {!!shareOverlay && preClose && (
        <ShareModal {...shareOverlay} closeUrl={closeUrl} />
      )}
    </Layout>
  )
}

MemorableDaysPageTemplateT14.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.shape({
    nodeLocale: PropTypes.string,
    pageLevel: PropTypes.string,
  }).isRequired,
}

export const memorableDaysPageT14Query = graphql`
  query(
    $id: String!
    $openingHoursId: String
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $collectionId: String
    $villageSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateMemorableDaysT14(id: { eq: $id }) {
      locale: node_locale
      jsonLdSchema {
        jsonLdSchema
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT14
        }
        ... on ContentfulCompHeroHer01 {
          id
          isCondensed
        }
      }
      shareBlock {
        headline
        bodyCopy
        ctaLabel: cta1Label
        heroCta: cta2Label
      }
      shareOverlay {
        headline
        bodyCopy
        ctaLabel: cta1Label
      }
      village {
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          header {
            logoIcon {
              code {
                code
              }
            }
          }
        }
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
        }
        defaultLocale
      }
      pageTitle
      pageDescription
      pageTemplate: __typename
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    collectionPage: contentfulPageTemplateHomeT01(id: { eq: $collectionId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default MemorableDaysPageTemplateT14
